import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Alternative_IP_Scanner/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Fing Mobile",
  "path": "/Quick_Installation/Alternative_IP_Scanner/Fing_Mobile/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Use the Fing Command Line Tool to discover your Camera on your local Network",
  "image": "./QI_SearchThumb_AlternativeIPScanner_FingMobile_eng.png",
  "social": "/images/Search/QI_SearchThumb_AlternativeIPScanner_FingMobile_eng.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Alternative_IP_Scanner_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Fing Mobile' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Fing Command Line Tool to discover your Camera on your local Network' image='/images/Search/QI_SearchThumb_AlternativeIPScanner_FingMobile_eng.png' twitter='/images/Search/QI_SearchThumb_AlternativeIPScanner_FingMobile_eng.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Alternative_IP_Scanner/' locationFR='/fr/Quick_Installation/Alternative_IP_Scanner/' crumbLabel="Fing Mobile" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "alternative-ip-scanner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alternative-ip-scanner",
        "aria-label": "alternative ip scanner permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alternative IP Scanner`}</h1>
    <h2 {...{
      "id": "fing-mobile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#fing-mobile",
        "aria-label": "fing mobile permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fing Mobile`}</h2>
    <h4 {...{
      "id": "how-to-find-your-ip-camera-in-the-network-with-an-alternative-ip-scanner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-to-find-your-ip-camera-in-the-network-with-an-alternative-ip-scanner",
        "aria-label": "how to find your ip camera in the network with an alternative ip scanner permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to find your IP Camera in the network with an alternative IP Scanner?`}</h4>
    <p>{`The `}<a parentName="p" {...{
        "href": "http://instar.de/media/wysiwyg/Products/Software/Camera_Tool_Win/INSTAR_Camera_Tool_2.0.5.msi.zip"
      }}>{`IP Camera Tool (Windows)`}</a>{`, `}<a href="https://itunes.apple.com/de/app/instar-camera-tool/id584435076?mt=12#" target="_blank" rel="noopener noreferrer">{`IP Camera Tool (macOS)`}</a>{` or `}<a parentName="p" {...{
        "href": "http://instar.de/media/wysiwyg/Products/Software/Camera_Tool_Linux/INSTAR_Camera_Tool_LINUX.zip"
      }}>{`IP Camera Tool (LINUX)`}</a>{` is for sure the most comfortable way to find your IP camera inside your network. Instructions on how to set up your INSTAR IP camera with the Camera Tool can be found here.`}</p>
    <p>{`If, for some reason, the IP Camera Tool does not work on your computer, here are a few other options to discover the IP address. The camera will try to receive an IP address automatically (DHCP) during the first installation. Most routers will give you a log file of fresh DHCP requests, in which you should be able to find the camera´s IP address. You should reach the cameras login portal by typing this address into the web browser´s address bar.`}</p>
    <p>{`If you do not have access to your router or if your router does not have any log files, please refer to alternative IP scanners - an examples is:`}</p>
    <h3 {...{
      "id": "fing-mobile-androidios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#fing-mobile-androidios",
        "aria-label": "fing mobile androidios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fing Mobile (Android/iOS)`}</h3>
    <h3 {...{
      "id": "fing-for-android",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#fing-for-android",
        "aria-label": "fing for android permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fing for Android`}</h3>
    <p>{`The newest version of the freeware Android App `}<em parentName="p">{`Fing`}</em>{` can be found on `}<a href="https://play.google.com/store/apps/details?id=com.overlook.android.fing&hl=en" target="_blank" rel="noopener noreferrer">{`Google Play`}</a>{` . After installation you will see the following interface:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0f4b0047d6ddb518ce8fd9177a28569c/20e5d/IPScanner_Android_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "166.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAhABQDASIAAhEBAxEB/8QAGQABAQEAAwAAAAAAAAAAAAAAAAMBAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAfM2M07aAk84jVNOBAr/xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIREhAg/9oACAEBAAEFAqgLCNREKuJmmjfv/8QAFxEAAwEAAAAAAAAAAAAAAAAAABARAf/aAAgBAwEBPwGEV1//xAAXEQADAQAAAAAAAAAAAAAAAAAAERIg/9oACAECAQE/AWVn/8QAGhAAAQUBAAAAAAAAAAAAAAAAAAEwMTJBof/aAAgBAQAGPwLCEK9d/8QAHxAAAgEDBQEAAAAAAAAAAAAAAAERITFBIFFhoeGB/9oACAEBAAE/IWysBkOp9HsxZ6JkuxWo+xkeizCHIi5D40f/2gAMAwEAAgADAAAAEIP68oPP/8QAGBEAAgMAAAAAAAAAAAAAAAAAABABEWH/2gAIAQMBAT8QTcmz/8QAGBEAAwEBAAAAAAAAAAAAAAAAABFxARD/2gAIAQIBAT8QkgQs7//EAB8QAQACAgICAwAAAAAAAAAAAAEAESExUYGR8BCh0f/aAAgBAQABPxCx+2ynLBoR7cLWXtzEpt8wkSpOaXFhYnviUP4IlRgOrZZvyzNCUpqu2FWl3DXwan//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f4b0047d6ddb518ce8fd9177a28569c/e4706/IPScanner_Android_01.avif 230w", "/en/static/0f4b0047d6ddb518ce8fd9177a28569c/e46ac/IPScanner_Android_01.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0f4b0047d6ddb518ce8fd9177a28569c/a0b58/IPScanner_Android_01.webp 230w", "/en/static/0f4b0047d6ddb518ce8fd9177a28569c/8626f/IPScanner_Android_01.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f4b0047d6ddb518ce8fd9177a28569c/e83b4/IPScanner_Android_01.jpg 230w", "/en/static/0f4b0047d6ddb518ce8fd9177a28569c/20e5d/IPScanner_Android_01.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0f4b0047d6ddb518ce8fd9177a28569c/20e5d/IPScanner_Android_01.jpg",
              "alt": "Fing Android App",
              "title": "Fing Android App",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let the Fing scan your network for a new active host.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8339280350e43d4fe20e20a81d330be3/20e5d/IPScanner_Android_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "166.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAhABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB8xn6S7EpSQaJhBAr/8QAHBAAAgEFAQAAAAAAAAAAAAAAAAEiAhARIDFB/9oACAEBAAEFAoGaURPXyzE9/wD/xAAXEQADAQAAAAAAAAAAAAAAAAAAEBEB/9oACAEDAQE/AYRXX//EABYRAQEBAAAAAAAAAAAAAAAAABEAIP/aAAgBAgEBPwFnP//EABgQAAMBAQAAAAAAAAAAAAAAAAABMjBB/9oACAEBAAY/ApRJwWn/xAAdEAACAgEFAAAAAAAAAAAAAAAAAREhQRAgUZHR/9oACAEBAAE/IbehhL2S3DSVO0jjAm5HcFGN/wD/2gAMAwEAAgADAAAAEAgoQgAP/8QAFxEBAQEBAAAAAAAAAAAAAAAAARAAYf/aAAgBAwEBPxCApu1//8QAFxEAAwEAAAAAAAAAAAAAAAAAABBxEf/aAAgBAgEBPxCCVj//xAAhEAEAAgEDBAMAAAAAAAAAAAABABExIUFhIFFxgcHR4f/aAAgBAQABPxBstZ84WAt9nAtpwqvxFaK3zr9y4Ytz9Zqa2uYApna4YY5oimHsdf8A/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8339280350e43d4fe20e20a81d330be3/e4706/IPScanner_Android_02.avif 230w", "/en/static/8339280350e43d4fe20e20a81d330be3/e46ac/IPScanner_Android_02.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8339280350e43d4fe20e20a81d330be3/a0b58/IPScanner_Android_02.webp 230w", "/en/static/8339280350e43d4fe20e20a81d330be3/8626f/IPScanner_Android_02.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8339280350e43d4fe20e20a81d330be3/e83b4/IPScanner_Android_02.jpg 230w", "/en/static/8339280350e43d4fe20e20a81d330be3/20e5d/IPScanner_Android_02.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8339280350e43d4fe20e20a81d330be3/20e5d/IPScanner_Android_02.jpg",
              "alt": "Fing Android App",
              "title": "Fing Android App",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let the Fing scan the host for active services.`}</p>
    <p>{`Make sure the camera is disconnected from your network - then start a scan. Now connect the camera and re-run the scan. The camera should show up as a new network device. Press the cameras IP address to open the advanced details. If you are using more than one camera in your network we recommend, you only connect one camera at a time in order to differentiate them.`}</p>
    <p>{`Please choose `}<em parentName="p">{`Scan services`}</em>{` to discover the cameras http port - the default http port is 80.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/733cbe5ef9c34dc68e3ccddd994dc95b/20e5d/IPScanner_Android_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "166.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAhABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAQBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB5qbFlqMahKAAB//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhESITD/2gAIAQEAAQUCqLEkXE3zRvy//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAg/9oACAEDAQE/ASM//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAg/9oACAECAQE/AWc//8QAFxAAAwEAAAAAAAAAAAAAAAAAADAyMf/aAAgBAQAGPwKTCG//xAAdEAEAAQMFAAAAAAAAAAAAAAABABExQTBRobHh/9oACAEBAAE/IbyjmBt3iHthYzvKsLBZV0v/2gAMAwEAAgADAAAAEOwQAAAP/8QAFhEBAQEAAAAAAAAAAAAAAAAAcQAg/9oACAEDAQE/EHPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxBX/8QAHxABAAIBAwUAAAAAAAAAAAAAAQARITBBUTFhgaHB/9oACAEBAAE/EHlDfL5KFNFyHV6lMIXmreOXC9yJNi8ECZDS/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/733cbe5ef9c34dc68e3ccddd994dc95b/e4706/IPScanner_Android_03.avif 230w", "/en/static/733cbe5ef9c34dc68e3ccddd994dc95b/e46ac/IPScanner_Android_03.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/733cbe5ef9c34dc68e3ccddd994dc95b/a0b58/IPScanner_Android_03.webp 230w", "/en/static/733cbe5ef9c34dc68e3ccddd994dc95b/8626f/IPScanner_Android_03.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/733cbe5ef9c34dc68e3ccddd994dc95b/e83b4/IPScanner_Android_03.jpg 230w", "/en/static/733cbe5ef9c34dc68e3ccddd994dc95b/20e5d/IPScanner_Android_03.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/733cbe5ef9c34dc68e3ccddd994dc95b/20e5d/IPScanner_Android_03.jpg",
              "alt": "Fing Android App",
              "title": "Fing Android App",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose the cameras web port 80....`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6575aee4c8d0b0793385615f5b81764f/20e5d/IPScanner_Android_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "166.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAhABQDASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAMFAQQGAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGlb/qyvbA6Gfj8y3CkEQAP/8QAHRAAAgICAwEAAAAAAAAAAAAAAgMAAQQzEBETIP/aAAgBAQABBQLjuDikQ1hMuNQSyVqqZm2nNqvdsthl8f/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BX//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BX//EACAQAAIBAwQDAAAAAAAAAAAAAAABAhEhMQMQIJEzQnH/2gAIAQEABj8C2wJ1VzMSjoQv6oyL4UWpLs8kuy8m+H//xAAgEAEAAQMDBQAAAAAAAAAAAAABABEhMRAgYUFxgZHw/9oACAEBAAE/IaD0hbBpAS2rmYT3sHOlK2ZTjdjiKuA+J9fLBAIWDR5ir3Ls/9oADAMBAAIAAwAAABBXEzAAD//EABYRAAMAAAAAAAAAAAAAAAAAAAERIP/aAAgBAwEBPxBGv//EABYRAQEBAAAAAAAAAAAAAAAAABEAIP/aAAgBAgEBPxBnP//EAB8QAQEAAgIBBQAAAAAAAAAAAAERADEhUUEgYYHw8f/aAAgBAQABPxBXU3vnGdR3LziDdfLh3KIr4peSYArp9NZFDHaSp17Y4ouhF0ywjeQjOl44CgMAwA8Z+/w+ZEF1no//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6575aee4c8d0b0793385615f5b81764f/e4706/IPScanner_Android_04.avif 230w", "/en/static/6575aee4c8d0b0793385615f5b81764f/e46ac/IPScanner_Android_04.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6575aee4c8d0b0793385615f5b81764f/a0b58/IPScanner_Android_04.webp 230w", "/en/static/6575aee4c8d0b0793385615f5b81764f/8626f/IPScanner_Android_04.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6575aee4c8d0b0793385615f5b81764f/e83b4/IPScanner_Android_04.jpg 230w", "/en/static/6575aee4c8d0b0793385615f5b81764f/20e5d/IPScanner_Android_04.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6575aee4c8d0b0793385615f5b81764f/20e5d/IPScanner_Android_04.jpg",
              "alt": "Fing Android App",
              "title": "Fing Android App",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`...and choose to open the address inside your default web browser.`}</p>
    <p>{`Press the http port and choose to open the camera´s `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`Web User Interface`}</a>{`.`}</p>
    <p>{`You will now be greeted by the camera´s login page - the default credentials are username `}<strong parentName="p">{`admin`}</strong>{` and the password can be left `}<strong parentName="p">{`blank`}</strong>{` (or type in `}<strong parentName="p">{`instar`}</strong>{` in case of a HD camera model). Congratulations! You now entered the cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`Web User Interface`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/84a9fe190efc5cf195641d2dc31413ff/20e5d/IPScanner_Android_05.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "166.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAhABQDASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAIDAQQFBv/EABcBAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAY3dXKcd2i61kJGUB5m0NUZ6/wD/xAAeEAACAgICAwAAAAAAAAAAAAAAAQIDEhMRFAQQMv/aAAgBAQABBQLpPnpIfhmTOZetsE91ZnGRnSZ1G6kY/g//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AV//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AV//xAAfEAABBAICAwAAAAAAAAAAAAAAAQIhMRAyEZFxgqH/2gAIAQEABj8Clv0p3aEIvZHBSY2NiFLaW02aeo/wmP/EAB4QAAIBBAMBAAAAAAAAAAAAAAABESExUaEQQYFh/9oACAEBAAE/IU9PaFIbKCrsoOShhNCX3E/BPJ0LFGRVymh9+WxGsGiE7fHBWf/aAAwDAQACAAMAAAAQDMp9cC//xAAYEQEAAwEAAAAAAAAAAAAAAAABABARIf/aAAgBAwEBPxAMrkxpn//EABYRAQEBAAAAAAAAAAAAAAAAAAERIP/aAAgBAgEBPxBbr//EAB4QAQADAQACAwEAAAAAAAAAAAEAESExQVFhcYHw/9oACAEBAAE/EGRwerP5A3QKa0j0uFbC+vxitzoD9rAQqf0JWJjSQ0WFQAjyEVVil9lm9JpHPfIuWYRcMPPEAYQ7iTnD+L7nh9T/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84a9fe190efc5cf195641d2dc31413ff/e4706/IPScanner_Android_05.avif 230w", "/en/static/84a9fe190efc5cf195641d2dc31413ff/e46ac/IPScanner_Android_05.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/84a9fe190efc5cf195641d2dc31413ff/a0b58/IPScanner_Android_05.webp 230w", "/en/static/84a9fe190efc5cf195641d2dc31413ff/8626f/IPScanner_Android_05.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84a9fe190efc5cf195641d2dc31413ff/e83b4/IPScanner_Android_05.jpg 230w", "/en/static/84a9fe190efc5cf195641d2dc31413ff/20e5d/IPScanner_Android_05.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/84a9fe190efc5cf195641d2dc31413ff/20e5d/IPScanner_Android_05.jpg",
              "alt": "Fing Android App",
              "title": "Fing Android App",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Login with the default username and password.`}</p>
    <h3 {...{
      "id": "fing-for-ios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#fing-for-ios",
        "aria-label": "fing for ios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fing for iOS`}</h3>
    <p>{`The newest version of the freeware iOS App `}<em parentName="p">{`Fing`}</em>{` can be found on the `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/app/id430921107"
      }}>{`App Store`}</a>{`. After installation you will see the following interface:`}</p>
    <p>{`Make sure the camera is disconnected from your network - then start a scan. Now connect the camera and re-run the scan. The camera should show up as a new network device. Press the cameras IP address to open the advanced details. If you are using more than one camera in your network we recommend, you only connect one camera at a time in order to differentiate them.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/be5ae9f2bb26b83ab2063e1c6d3db79d/20e5d/IPScanner_iOS_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB06fRI5YDLiNbhCD/xAAdEAACAQQDAAAAAAAAAAAAAAAAAQMREhMVBSEx/9oACAEBAAEFAsUximMUzPTuiLCwsRsWbFj5F1//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAwEBAAAAAAAAAAAAAAAAATIzoSCR/9oACAEBAAY/Arn4W4XZ3BEEQR//xAAeEAEAAgICAwEAAAAAAAAAAAABABExUSFBYXGhsf/aAAgBAQABPyF6Qih4ZvaOYps5H4w6qr1MMRilF/IAdwWt1ZnD5qzBoGB3P//aAAwDAQACAAMAAAAQvDZN/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EIirr//EABYRAAMAAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxAj/8QAIBABAAIBBAIDAAAAAAAAAAAAAQARITFBUYFhkaHB4f/aAAgBAQABPxAFB4A08+dYAPHh/aUjZMWrPzGoglvsYqbYtFSoro3sJEspO8wz1EioDSZYd7jEEt2TTn8jPwAqF6n/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be5ae9f2bb26b83ab2063e1c6d3db79d/e4706/IPScanner_iOS_01.avif 230w", "/en/static/be5ae9f2bb26b83ab2063e1c6d3db79d/e46ac/IPScanner_iOS_01.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/be5ae9f2bb26b83ab2063e1c6d3db79d/a0b58/IPScanner_iOS_01.webp 230w", "/en/static/be5ae9f2bb26b83ab2063e1c6d3db79d/8626f/IPScanner_iOS_01.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be5ae9f2bb26b83ab2063e1c6d3db79d/e83b4/IPScanner_iOS_01.jpg 230w", "/en/static/be5ae9f2bb26b83ab2063e1c6d3db79d/20e5d/IPScanner_iOS_01.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/be5ae9f2bb26b83ab2063e1c6d3db79d/20e5d/IPScanner_iOS_01.jpg",
              "alt": "Fing iOS App",
              "title": "Fing iOS App",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let the Fing scan your network for a new active host.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d7ebecd839e7d347a958d09191b8646d/20e5d/IPScanner_iOS_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHTOuxz3QFCwQP/xAAcEAABBAMBAAAAAAAAAAAAAAAAAQMREgQQFCH/2gAIAQEAAQUCRh453RcZ4t7ZSdoVIIP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAXEAADAQAAAAAAAAAAAAAAAAAAM6Ew/9oACAEBAAY/AnQdB8y//8QAIhAAAgIABAcAAAAAAAAAAAAAAAERMSFBsdEQUXGBkeHw/9oACAEBAAE/IUvduJNQ7txizlgnQaMxO7Zm8NB/UUIc35Org//aAAwDAQACAAMAAAAQ88RN/8QAFhEBAQEAAAAAAAAAAAAAAAAAACEQ/9oACAEDAQE/EMiP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAIBABAAMAAgEFAQAAAAAAAAAAAQARITFBsYGhwdHw8f/aAAgBAQABPxAR3WdXiBEFPdEMGiFXZv3nOCrrn+oBR4Qq7H0+JWyncshK3pH4yLLnccPoQPas/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7ebecd839e7d347a958d09191b8646d/e4706/IPScanner_iOS_02.avif 230w", "/en/static/d7ebecd839e7d347a958d09191b8646d/e46ac/IPScanner_iOS_02.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d7ebecd839e7d347a958d09191b8646d/a0b58/IPScanner_iOS_02.webp 230w", "/en/static/d7ebecd839e7d347a958d09191b8646d/8626f/IPScanner_iOS_02.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7ebecd839e7d347a958d09191b8646d/e83b4/IPScanner_iOS_02.jpg 230w", "/en/static/d7ebecd839e7d347a958d09191b8646d/20e5d/IPScanner_iOS_02.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d7ebecd839e7d347a958d09191b8646d/20e5d/IPScanner_iOS_02.jpg",
              "alt": "Fing iOS App",
              "title": "Fing iOS App",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let the Fing scan the host for active services.`}</p>
    <p>{`Please choose "Scan services" to discover the cameras http port - the default http port is 80.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ab2603cf444940e0987a0a1e52b9ad71/20e5d/IPScanner_iOS_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBQP/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHpOtYxmwLAkH//xAAbEAABBAMAAAAAAAAAAAAAAAAAAQMRExIgIf/aAAgBAQABBQKl4qdKXVMewRO//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHBAAAQMFAAAAAAAAAAAAAAAAAAEzoREwMTSR/9oACAEBAAY/AtheD8D8FTK2P//EAB8QAAIBAgcAAAAAAAAAAAAAAAERABBBICExobHB8P/aAAgBAQABPyFFiVZhOW8Po9whcdAR1PH/AP/aAAwDAQACAAMAAAAQZ98A/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8QwpT/AP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExYXFRscH/2gAIAQEAAT8Q1ehh9gwVnaChRqrClbXDXYKtPjUwr2RS0nuPcdbhK5lcwn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ab2603cf444940e0987a0a1e52b9ad71/e4706/IPScanner_iOS_03.avif 230w", "/en/static/ab2603cf444940e0987a0a1e52b9ad71/e46ac/IPScanner_iOS_03.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ab2603cf444940e0987a0a1e52b9ad71/a0b58/IPScanner_iOS_03.webp 230w", "/en/static/ab2603cf444940e0987a0a1e52b9ad71/8626f/IPScanner_iOS_03.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ab2603cf444940e0987a0a1e52b9ad71/e83b4/IPScanner_iOS_03.jpg 230w", "/en/static/ab2603cf444940e0987a0a1e52b9ad71/20e5d/IPScanner_iOS_03.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ab2603cf444940e0987a0a1e52b9ad71/20e5d/IPScanner_iOS_03.jpg",
              "alt": "Fing iOS App",
              "title": "Fing iOS App",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose the cameras web port 80....`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce42d7368b5bb86c213b6fbc3b957289/20e5d/IPScanner_iOS_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAMEAQIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGfNrdTmlpmoYl5MaB//8QAHBAAAgMBAAMAAAAAAAAAAAAAAQIAAyESERMi/9oACAEBAAEFAvqbApMFlqz23TXitnUa0Ia9LDwvRE//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAhEAABAgYCAwAAAAAAAAAAAAAAAhEBEiExMqGBkSJxgv/aAAgBAQAGPwLGPBgosxKlNPRVLfI6rj+PZdPZilXI02iMZtF9H//EAB4QAAICAQUBAAAAAAAAAAAAAAERACFhMUFRgeGh/9oACAEBAAE/IQAYet4Q+8syWBMeEIa1wLs7YzEVsKAIMLGmyL64qXLYgzeB48QjDQ4w1B85/9oADAMBAAIAAwAAABBD6Dz/xAAXEQEAAwAAAAAAAAAAAAAAAAABABAR/9oACAEDAQE/EAmX/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQIf/aAAgBAgEBPxCLt//EAB4QAQACAgMAAwAAAAAAAAAAAAEAESFBMWGhcdHw/9oACAEBAAE/EEEsqyI+QsVk6+kBUI1av0iSFVh2vPIx13vxuXPCb1AcQbUxyj0inKhpyQw1DJ41XHXse1gtbHA7hndZBzexKzWX5FZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce42d7368b5bb86c213b6fbc3b957289/e4706/IPScanner_iOS_04.avif 230w", "/en/static/ce42d7368b5bb86c213b6fbc3b957289/e46ac/IPScanner_iOS_04.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce42d7368b5bb86c213b6fbc3b957289/a0b58/IPScanner_iOS_04.webp 230w", "/en/static/ce42d7368b5bb86c213b6fbc3b957289/8626f/IPScanner_iOS_04.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce42d7368b5bb86c213b6fbc3b957289/e83b4/IPScanner_iOS_04.jpg 230w", "/en/static/ce42d7368b5bb86c213b6fbc3b957289/20e5d/IPScanner_iOS_04.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce42d7368b5bb86c213b6fbc3b957289/20e5d/IPScanner_iOS_04.jpg",
              "alt": "Fing iOS App",
              "title": "Fing iOS App",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`...and choose to open the address inside your default web browser.`}</p>
    <p>{`Press the http port and choose to open the camera´s `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`Web User Interface`}</a>{`.`}</p>
    <p>{`You will now be greeted by the camera´s login page - the default credentials are username `}<strong parentName="p">{`admin`}</strong>{` and the password can be left `}<strong parentName="p">{`blank`}</strong>{` (or type in `}<strong parentName="p">{`instar`}</strong>{` in case of a HD camera model). Congratulations! You now entered the cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`Web User Interface`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      